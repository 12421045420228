<template>
  <span class="">
    <svg :class="size" viewBox="0 0 12.7 12.7">
      <path d="m6.0707 9.4377c-0.024687-0.05695-0.032209-0.16037-0.016718-0.22983 0.01549-0.069454 0.018967-0.13466 0.0077257-0.1449-0.011243-0.010238-0.40749-0.50403-0.88055-1.0973-0.47306-0.59328-0.86667-1.0784-0.8747-1.078-0.00802 3.511e-4 -0.025466 0.09561-0.038752 0.21166-0.013289 0.11606-0.036166 0.19917-0.050842 0.1847-0.047338-0.046696-0.77868-1.8528-0.73855-1.8239 0.021325 0.015326 0.38943 0.27627 0.81803 0.57987 0.4286 0.30359 0.77998 0.56151 0.78085 0.57315 8.44e-4 0.011679-0.088015 0.00696-0.19752-0.010406-0.10951-0.017365-0.1981-0.018112-0.19687-0.00166 0.00123 0.016455 0.3869 0.51155 0.85704 1.1002l0.85481 1.0703 0.16649 0.012374c0.62175 0.046217 0.60214 0.95694-0.02109 0.97975-0.18189 0.0066549-0.3869-0.13572-0.46936-0.32594z"
      style="opacity:1;fill:#000000;"/>
      <path
         id="path4799-7-9-17"
         d="M 0.29866582,9.0945983 C 0.34407927,8.4705434 0.49225659,7.6029064 0.63792557,7.1080767 0.76941868,6.6613512 1.1456554,5.7094473 1.2098561,5.6609976 c 0.028341,-0.021365 2.2502824,1.4913253 2.364858,1.609978 0.00965,0.0099 -0.039196,0.136334 -0.1085244,0.2807723 C 3.2678125,7.9649376 3.1177751,8.5091797 3.0541432,9.0463893 L 2.9955513,9.5410338 H 1.6308632 0.26617397 Z"
         style="opacity:1;fill:currentColor;fill-opacity:0.1;stroke:none;stroke-width:0.08927178;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" />
      <path
         transform="scale(0.26458333)"
         id="path866"
         d="M 9.3479077,23.965266 C 7.1604922,22.421406 5.3152958,21.068458 5.2474716,20.958716 5.0641089,20.662029 7.374367,17.186674 8.8074241,15.603427 c 1.5857419,-1.751933 4.4745569,-4.146626 4.6993289,-3.895525 0.09418,0.105218 1.216633,2.422266 2.49433,5.148997 l 2.323084,4.957693 -2.389824,2.478846 c -1.314404,1.363366 -2.43921,2.478846 -2.49957,2.478846 -0.06036,0 -1.89945,-1.263158 -4.0868653,-2.807018 z"
         style="opacity:0.2;fill:currentColor;fill-opacity:1;stroke:none;stroke-width:0.96090955;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
       <path
          transform="scale(0.26458333)"
          id="path868"
          d="m 16.484475,16.284858 -2.429827,-5.148373 1.052089,-0.560029 c 2.149122,-1.1439821 6.631004,-2.3547275 8.742869,-2.3618178 l 0.572041,-0.00192 v 5.9457878 5.945788 l -1.153791,0.173022 c -0.634585,0.09516 -1.796579,0.394173 -2.582208,0.664468 -0.785629,0.270297 -1.505576,0.491448 -1.599881,0.491448 -0.09431,0 -1.264887,-2.316768 -2.601292,-5.148374 z"
          style="opacity:0.05;fill:currentColor;fill-opacity:1;stroke:none;stroke-width:0.96090955;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
       <path
          transform="scale(0.26458333)"
          id="path870"
          d="m 29.489902,21.399633 c -0.498521,-0.259518 -1.671206,-0.660585 -2.605967,-0.89126 l -1.699566,-0.41941 V 14.147836 8.2067086 l 1.080523,0.1649348 c 3.348225,0.5110849 8.634345,2.5820766 8.99596,3.5244306 0.158683,0.413519 -4.225279,9.850841 -4.610968,9.925997 -0.13947,0.02718 -0.661462,-0.16292 -1.159982,-0.422438 z"
          style="opacity:0.05;fill:currentColor;fill-opacity:1;stroke:none;stroke-width:0.96090955;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
       <path
          transform="scale(0.26458333)"
          id="path872"
          d="M 35.215718,26.704554 C 34.825873,26.11256 33.794953,24.884548 32.924785,23.975637 l -1.582123,-1.652564 2.368089,-4.894132 c 1.302449,-2.691773 2.470315,-4.894133 2.595259,-4.894133 0.124943,0 1.200981,1.001073 2.391194,2.224606 2.143041,2.203034 3.715909,4.298909 4.744765,6.322482 l 0.532327,1.046989 -3.738109,2.661059 c -2.05596,1.463583 -3.867158,2.735288 -4.024885,2.826011 -0.16457,0.09466 -0.588823,-0.293719 -0.995584,-0.911401 z"
          style="opacity:0.05;fill:currentColor;fill-opacity:1;stroke:none;stroke-width:0.96090955;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
       <path
          transform="scale(0.26458333)"
          id="path874"
          d="m 37.786689,34.374693 c -0.09647,-0.85264 -0.460742,-2.534938 -0.809503,-3.73844 l -0.634111,-2.188185 0.585983,-0.449969 c 1.064851,-0.817684 7.277817,-5.039424 7.416319,-5.039424 0.0747,0 0.540321,1.251267 1.034719,2.780593 0.967091,2.991511 1.6663,6.701847 1.667946,8.850916 l 10e-4,1.334763 h -4.54347 -4.543492 z"
          style="opacity:0.05;fill:currentColor;fill-opacity:1;stroke:none;stroke-width:0.96090955;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
    </svg>
  </span>
</template>

<script>

export default {
  name: 'iconlembrar',
  props: [ "size" ],
  components: {},
}
</script>

<style scoped>

</style>
